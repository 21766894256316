
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "bef9a101-c878-4cb5-afd1-96f087d0514f"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/saimo/WebstormProjects/hub-rma-service/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
